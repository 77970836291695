.mar{
  padding: 15px;
}
.form {
  max-width: 550px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  background: white;
  padding: 20px;
  margin-top: 30px;
}